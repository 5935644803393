<template>
  <!--  Button has 3 options:
  inverted for dark, red for light background
   & optional icon -->
  <div
    ref="button"
    class="button"
    :class="{ inverted, red, external, borderless }"
  >
    <span>
      <slot />
      <Icon v-if="icon || external" :icon="icon || 'arrow-down'" />
    </span>
  </div>
</template>

<script lang="ts" setup>
import { useMouseInElement } from '@vueuse/core'

const props = defineProps<{
  icon?: string
  inverted?: boolean
  external?: boolean
  red?: boolean
  borderless?: boolean
}>()

const isColorSchemeInverted = colorSchemeIsInverted()
const button = ref(null)

const { elementX, elementY, isOutside } = useMouseInElement(button)

const activeColor = computed(() =>
  isColorSchemeInverted.value ? '#C4C0B3' : '#23231C'
)
const invertedColor = computed(() =>
  isColorSchemeInverted.value ? '#23231C' : '#C4C0B3'
)
</script>

<style lang="scss" scoped>
$timing: cubic-bezier(0.03, 0.79, 0.27, 0.92);
.button {
  isolation: isolate;
  cursor: pointer;
  overflow: hidden;
  transition: opacity, scale, color, border, background, filter;
  transition-duration: 0.33s;
  position: relative;
  width: fit-content;
  min-width: 15rem;
  padding: 1em 2em;
  font-size: $font-16;
  text-transform: uppercase;
  color: v-bind(activeColor);
  border: 1px solid currentColor;
  border-radius: 5em;
  background: none;

  &:after {
    content: '';
    pointer-events: none;
    transition: opacity 0.2s, width 0.6s, height 0.6s, border-radius 0.5s 0.15s;
    opacity: 0;
    translate: -50% -50%;
    position: absolute;
    aspect-ratio: 1;
    width: 0;
    height: 0;
    top: calc(v-bind(elementY) * 1px);
    left: calc(v-bind(elementX) * 1px);
    background: v-bind(activeColor);
    border-radius: 50%;
  }
  &.borderless {
    &:after {
      content: none;
    }
  }

  &.borderless {
    overflow: visible;
    padding: unset;
    border: none;
  }

  &.inverted {
    color: $color-inverted;

    &:after {
      background: $color-inverted;
    }
  }

  &.red {
    color: $color-primary;
    &:after {
      background: $color-primary;
    }
  }

  &.external {
    svg {
      rotate: -135deg;
      height: 0.75em;
    }
  }

  span {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    text-align: center;
    line-height: 1.2;
  }

  svg {
    transition: translate 0.33s, color 0.33s;
    aspect-ratio: 1;
    height: 1em;
    margin-left: 0.5em;
  }

  @media (hover: hover) {
    &:hover {
      &:not(.borderless) {
        color: v-bind(invertedColor) !important;
        border: 1px solid $color-text;
        &.inverted {
          color: $color-text !important;
          border: 1px solid transparent;
        }
        &.red {
          color: $color-inverted !important;
          border: 1px solid $color-primary;
          &:after {
            background: $color-primary;
          }
        }
      }
      &:after {
        opacity: 1;
        width: 200%;
        height: auto;
        border-radius: 0;
      }

      &.external {
        svg {
          translate: 2px -2px;
        }
      }
    }
  }
}
</style>
